import Script from 'next/script'

export function RDIntegration() {
  return (
    <Script
      id="rd-integration"
      strategy="beforeInteractive"
      src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/338a8208-fbea-4882-94f7-0acf0b2f92c6-loader.js"
      type={'text/javascript'}
    />
  )
}
